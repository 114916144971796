<template>

    <v-dialog v-model="showDialog" max-width="800px">
        <v-card>
            <v-card-title>
                SKU {{product_reference}} en {{marketplace_name}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col
                    cols="12"
                    >
                    <pre class="json-output">{{info}}</pre>
                    </v-col>
                </v-row>
            </v-card-text>            
        </v-card>
    </v-dialog>
</template>

<style scoped>
.json-output {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            info: "",
            product_reference: "",
            marketplace_name: "",
            loading: false            
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('openDialogInfoProductoMkp', (info,sku,marketplace) => {
            vm.info = info.length > 0 ? JSON.stringify(info, null, 2) : 'No se encontró información en el canal';
            vm.product_reference = sku;
            vm.marketplace_name = marketplace;
            vm.showDialog = true;            
        });        
    },
    mounted()
    {
        
    }
}
</script>